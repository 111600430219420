<template>
  <div>
    <div class="container">
      <div class="row justify-content-otp">
        <div class="col-md-4 text-center">
          <div class="account-border">
            <div class="row page-login">
              <form @submit="checkForm">
                <div class="col-sm-12 merchant-login">
                  <div class>
                    <h2>
                      <i class="fa fa-lock" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp; OTP Verification
                    </h2>
                    <p>
                      <strong>The OTP code has been sent to your mobile number</strong>
                    </p>
                    <p>{{object.mobile}}</p>
                    <div class="form-group">
                      <label
                        class="control-label"
                        for="input-email"
                      >Please Enter Your OTP Code - Expires in {{countdownseconds}} seconds</label>
                      <input
                        type="text"
                        required
                        v-model="sentotp"
                        placeholder="Enter OTP Code"
                        class="form-control"
                      />
                    </div>
                    <div class="col-md-12 text-center">
                      <button class="btn btn-primary pr-1" :disabled="!blockresendopt">Submit</button> &nbsp;
                      <button
                        @click.prevent.stop="resentOTP()"
                        class="btn btn-primary pl-2"
                        :disabled="blockresendopt"
                      >Resent OTP</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mixinAuth from "../../mixins/buyer/APIAuth";
import { functions } from "firebase";
export default {
  props: ["object"],
  mixins: [mixinAuth],
  data() {
    return {
      sentotp: "",
      countdownseconds: 0,
      blockresendopt: true
    };
  },
  watch: {
    countdownseconds: function(val) {
      if (val === 0) {
        this.blockresendopt = false;
      } else {
        this.blockresendopt = true;
      }
    }
  },
  created() {
    this.init();
    this.checkRemainTime();
  },
  methods: {
    checkRemainTime: async function() {
      try {
        if (localStorage.OTP_Buyer_Id) {
          if (localStorage.OTP_Buyer_Id !== "") {
            let obj = {
              _id: localStorage.OTP_Buyer_Id
            };

            let response = await this.PostbuyerMobileOTPremain(obj);
          }
        }
        this.countdownCal(90);
      } catch (error) {
        console.log(error);
      }
    },
    init() {
      if (this.$route.params.object != undefined) {
        this.object = this.$route.params.object;
      } else {
        this.object.mobile = "";
      }
    },
    countdownCal(duration) {
      duration = 90;
      this.countdownseconds = duration;
      let self = this;
      clearInterval(downloadTimer);
      var downloadTimer = setInterval(function() {
        self.countdownseconds -= 1;
        if (self.countdownseconds <= 0) {
          clearInterval(downloadTimer);
        }
      }, 1000);
    },
    resentOTP: async function() {
      try {
        let res = await this.buyerMobileResend(this.object._id);
        this.$swal.fire({
          position: "center",
          type: "success",
          title: "Successfully resent OTP",
          showConfirmButton: false,
          timer: 2000
        });
        this.countdownCal(90);
      } catch (error) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "Failed to resent OTP",
          showConfirmButton: false,
          timer: 2000
        });
      }
    },
    register() {
      this.$router.push({ name: "buyer register" });
    },
    handleLogin() {
      this.$router.push({ name: "buyer login" });
    },
    checkForm: async function(e) {
      e.preventDefault();

      try {
        let obj = {
          code: this.sentotp,
          _id: this.object._id
        };
        let res = await this.buyerMobileVerify(obj);
        this.handleLogin();
        this.$swal.fire({
          position: "center",
          type: "success",
          title: "Successfully verified OTP!",
          showConfirmButton: false,
          timer: 1500
        });
      } catch (error) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "Invalid OTP - " + error.data.message,
          showConfirmButton: false,
          timer: 2000
        });
      }
      // let obj = {
      //   code: this.sentotp,
      //   _id: this.object._id
      // }
      // console.log(this.sentotp);
      // console.log(obj);
      // let res = await this.merchantMobileVerify(obj);

      if (res.status == 409) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "Wrong OTP Code! Please try again.",
          showConfirmButton: false,
          timer: 1500
        });
      }

      //   if(res.status === 200){
      //     this.handleLogin();
      //   }

      //this.handleContinue();
    }
  }
};
</script>
<style scoped>
.justify-content-otp {
  display: flex;
  justify-content: center;
}
</style>